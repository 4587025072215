@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@600&display=swap');

.contactParent {
    height: fit-content;
    background-image: url("contactBg.png");
    padding-top: 40px;
    padding-bottom: 10px;
    font-family: "Poppins";
}

.loader {
    position: fixed;
    background-color: rgba(255, 255, 255, 0.5);
    padding: 100vh 100vw 100vh 100vw;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);  
    z-index: 1;
}

.container{
    column-count: 2;
    column-gap: 10%;
    column-rule-style:solid;  
    column-rule-color: #0b42c1;
    margin-top:100px;
    margin-bottom: 100px;
    margin-left: 45%;
    transform: translateX(-50%);
}
.contactDescription {
    font-size: 18px;
    text-align: end;
    color: #193E7E;
    text-wrap:pretty;
    margin-top: 50%;
}

.box{
    width:45vw;
    border-radius: 10px;
    padding: 50px;
    background-image: radial-gradient(#3772ff, #1c53d5);
    overflow:hidden;
}

.box option {
    font-family: "Poppins";
}

.label{
    font-size: larger;
    font-family: "Poppins";
    display: block;
    margin-top: 20px;
    color: #FFFFFF;
}

.box input {
    padding: 3px 10px;  
    border-radius: 20px;
    width: 150px;
    border: 2px solid #ccc;
    margin-right: 10%;
}

.input:focus {
    border: 2px solid #000000;
}

.select {
    padding: 5px 5px; 
    border-radius: 10px;
    margin-right: 5%;
}

.textarea{
    font-family: "Poppins";
    width: 100%;
    padding: 12px 20px;
    border: 2px solid #ccc;
    border-radius: 5px;
    font-size: 15px;
    margin-top:15px;
}

.textarea:focus {
    background-color: #e0f7ff;
    border: 2px solid #555;
}

.textarea {
    resize: none;  
}

.submit{
    display: block;
    background-color: #0b42c1;
    color: white;
    font-weight: bolder;
    padding: 10px 15px; 
    font-size: 12px; 
    border-radius: 20px;
    margin-right: 30px;
    margin-bottom: 15px;
    margin-top: 15px;
}

.submit:hover {
    border-width: 3px;
}

.err{
    color: white;
    background-color: red;
    padding: 3px;
    position:relative;
    white-space: nowrap;
}

.thanksBox{
    height: fit-content;
    margin-left: 30%;
    margin-right: 30%;
    margin-top:5%;
    margin-bottom:25%;

    padding:30px;
    border-radius: 10px;
    border-style:solid dashed solid dashed;
}

.thanksTitle{
    color: white;
    text-align: center;
    font-size: 30px;
    background-color: #3772ff;
    border-radius: 10px;
}

.thanksText{
    text-align: center;
    font-size: 20px;
    width: 100%;
    text-align: center;
    text-wrap: balance;
    margin-top:5%;
}


.thanksButton {
    background-color: white;
    font-size: 15px;
    border-radius: 30px;
    border-color: blue;

    display: block;
    margin: auto;
    margin-top: 5%;
    padding: 0.5% 50px 0.5% 50px;
}

.thanksButton:hover {
    border-width: 4px;
}

.thanksButton a {
    text-decoration: none;
}

.emailConf {
    text-align: center;
}


@media (max-width: 1000px) {
    .container{
        column-count: 1;
        justify-content: center;
        margin-left: 0;
        transform: translateX(0%);
    }
    .contactDescription {
        font-size: 14px;
        text-align: center;
        margin-top: 5%;
        width: 100vw;
    }
    .box{
        margin-left: 15vw;
        width: 70vw;
        border-style:ridge;
        border-radius: 10px;
        background-color:#B3989F;
    }    
    .label{
        font-size:medium;
    }    

    .thanksBox {
        width: 80vw;
        margin: 10vw;
    }
}

@media (max-width: 500px) {
    .select, .box input {
        width: 80%;
    }

}

