/** {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
}

body {
    background-color: #f4f4f4;
    color: #333;
    line-height: 1.6;
}
*/
.EventsParent {
    padding-top: 50px;
    margin-top: 80px;
    font-family: "Poppins", sans-serif;
}  

.timelineHeader h1 {
    font-size: 2.5rem;
}

.timeline {
    margin-top: -5%;
    display: flex;
    flex-direction: center;
    align-items: center;
    padding: 2rem 0;
    padding-left: 0rem;
}

.container {
    width: 80%;
    max-width: 800px;
    position: relative;
    box-sizing: border-box;
}

.timelineItem {
    position: relative;
    padding: 1rem;
    margin: 2rem 0;
    background-color: #fff;
    border-left: 3px solid #0b42c1; 
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.timelineDate {
    font-size: 1.25rem;
    color: black;
    font-weight: bold;
}

.timelineContent {
    padding-top: 1rem;
    width: 100%;
}

.timelineContent h3 {
    margin-top: 0.5rem;
    font-size: 1.5rem;
}

.timelineContent p {
    margin-top: 0.5rem;
    font-size: 1rem;
    color: #555;
}

@media (max-width: 500px) {
    .container {
        width: 90%;
    }
}
.navBarCover {
    height: 80px;
    background-color: #0b42c1;
    width: 100%;
    z-index: 3;
    display: flex;
    position: fixed;
    margin-top: -80px;
}