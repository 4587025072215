.teamParent {
    padding-top: 0px;
    margin-top: 80px;
    font-family: "Poppins", sans-serif;
}  
.navBarCover {
    height: 80px;
    background-color: #0b42c1;
    width: 100%;
    z-index: 3;
    display: flex;
    position: fixed;
    margin-top: -80px;
}
  .responsive-cell-block {
    min-height: 75px;
  }
  
  .text-blk {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    line-height: 25px;
  }
  
  .responsive-container-block {
    min-height: 75px;
    height: fit-content;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 0px;
    margin-right: auto;
    margin-bottom: 0px;
    margin-left: auto;
    justify-content: space-evenly;
  }
  
  .team-head-text {
    font-size: 48px;
    font-weight: 900;
    text-align: center;
  }
  
  .team-head-text {
    line-height: 50px;
    width: 100%;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 50px;
    margin-left: 0px;
  }
  
.teamContainer {
    max-width: 1380px;
    padding-top: 50px;
    padding-right: 30px;
    padding-bottom: 80px;
    padding-left: 30px;
}
  
  .card {
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.05) 6px 12px 4px 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
    padding-right: 25px;
    padding-bottom: 30px;
    padding-left: 25px;
    border-radius: 30px;
    height: 400px;
  }
  
  .card-container {
    width: 300px;
    margin-top: 0px;
    margin-right: 10px;
    margin-bottom: 25px;
    margin-left: 10px;
    
  }
  
  .name {
    margin-top: 20px;
    margin-right: 0px;
    margin-bottom: 5px;
    margin-left: 0px;
    font-size: 18px;
    font-weight: 800;
  }
  
  .position {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 10px;
    margin-left: 0px;
  }
  
  .feature-text {
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 20px;
    margin-left: 0px;
    color: rgb(122, 122, 122);
    line-height: 30px;
    
  }  

  .team-image-wrapper {
    clip-path: circle(50% at 50% 50%);
    width: 130px;
    height: 130px;
  }
  
  .team-member-image {
    max-width: 100%;
  }

  @media (max-width: 500px) {
    .card-container {
      width: 100%;
      margin-top: 0px;
      margin-right: 0px;
      margin-bottom: 25px;
      margin-left: 0px;
    }
  }

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}