.missionContainer {
    width: 100%;
    max-width: 100%;
    height: fit-content;
    background-color: #f4fdff;
    border: 0;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 100px;
    background-image: linear-gradient(0deg, #f4fdff, #63dbfe);
}
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@600&display=swap');
.missionContent {
    width: 70%;
    text-align: center;
    
}
.missionTitle {
    font-family: 'Titillium Web', sans-serif;
    font-size: 65px;
    text-align: center;

}
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap");
.missionText {
    font-family: Poppins, sans-serif;
    font-size: 25px;
    line-height: 2.5em;
}
@media (max-width: 1000px) {
    .missionTitle {
        font-size: 50px;
    }
    .missionText {
        font-size: 20px;
        text-align: left;
    }
    .missionContent {
        width: 90%;
    }
    .missionContainer {
        padding-bottom: 0px;
    }
}