.aidWorks5kInfoParent {
  width: 100%;
  height: 1225px;
  padding: 0;
  margin: 0;
  display:flex;
  top: 0px;
  background-image: linear-gradient(180deg, #f9c6b4, #fff);
}
.aidWorks5kInfoContent {
  width: 100%;
}
.aidWorks5kInfoTitle {
  font-family: 'Titillium Web', sans-serif;
  font-size: 65px;
  text-align: center;
}
.aidWorks5kColumns {
  display: flex;
  text-align: center;

}
.cost {
  width: 30%;
  margin-left: 3%;
  border-style: double;
  border-radius: 30px;
  padding-top: 15px;
  border-width: 10px;
  padding-bottom: 30px;
}
.benefits {
  width: 30%;
  margin-left: 2%;
  border-style: double;
  border-radius: 30px;
  padding-top: 15px;
  border-width: 10px;
  padding-bottom: 30px;
}
.prizes {
  width: 30%;
  margin-left: 2%;
  border-style: double;
  border-radius: 30px;
  padding-top: 15px;
  border-width: 10px;
  padding-bottom: 30px;
}
.columnPhotos {
  width: 80%;
  border-radius: 30px;
}
.columnTextBreak {
  width: 80%;
  height: 5px;
  background-color: #000000;
  color: #000000;
  border-color: #000000;
  border-radius: 30px;
}
.columnTitles {
  font-family: "Poppins";
}
.columnText {
  font-family: "Poppins";
  width: 90%;
  margin-left: 5%;
}
.countdown-container {
  text-align: center;
}

.countdown-item {
  display: inline-block;
  margin: 10px;
  font-size: 40px;
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
}
.countdown {
  display: inline-block;
  margin: 10px;
  font-size: 24px;
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
}
.countlabel{
  height: 50px;
  width: 425px;
  margin-left: 50%;
  font-size: 25px;
  transform: translateX(-50%);
}
.label1{
  margin-top: 0px;
  color: black;
  font-family: "Poppins";
  float:left;
  margin-right: 11%;
  margin-left: 6%;
}
.label2{
  margin-top: 0px;
  color: black;
  font-family: "Poppins";
  float:left;
  margin-right: 9%;
}
.label3{
  margin-top: 0px;
  color: black;
  font-family: "Poppins";
  float:left;
  margin-right: 12%;
}
.label4{
  margin-top: 0px;
  color: black;
  font-family: "Poppins";
  float:left;
}



@media (max-width: 900px) {
  .aidWorks5kInfoParent {
    height: 2600px;
    overflow: hidden;
    overflow-y: visible;
  }
  .aidWorks5kInfoContent {
      width: 95%;
      margin-left: 2.5%;
  }
  .aidWorks5kInfoTitle {
      font-family: 'Titillium Web', sans-serif;
      font-size: 65px;
      text-align: center;
      margin-top: 10%;
  }
  .aidWorks5kColumns {
      display:contents;
      width: 95%;
      text-align: top;
  }
  .cost {
    width: 95%;
    margin-left: 2.5%;
    margin-bottom: 10px;
  }
  .benefits {
    width: 95%;
    margin-left: 2.5%;
    margin-bottom: 10px;
  }
  .prizes {
    width: 95%;
    margin-left: 2.5%;
  }

  .columnPhotos {
      width: 80%;
      border-radius: 30px;
      margin-top:5%;
  }
  .columnText {
      font-family: "Poppins";
      width: 90%;
      margin-left: 5%;
  }
  .countdown-container {
    text-align: center;
    margin-top: 10%;
    margin-bottom: 10%;
  }
  .countdown-item {
    font-size: 22px;
  }
  .countlabel {
    font-size: 20px;
  }
  .label1{
    margin-top: 0px;
    color: black;
    font-family: "Poppins";
    float:left;
    margin-right: 9%;
    margin-left: 14%;
  }
  .label2{
    margin-top: 0px;
    color: black;
    font-family: "Poppins";
    float:left;
    margin-right: 8%;
  }
  .label3{
    margin-top: 0px;
    color: black;
    font-family: "Poppins";
    float:left;
    margin-right: 10%;
  }
}