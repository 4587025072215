.navbar{
    position: fixed;
    z-index: 999;
    top: 0px;
    justify-content: center;
    height: 60px;
    width: 100%;
}

.navbar-container{
    background-color:rgba(0, 0, 0, 0);
    font-size: 25px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 100;
}

.navbar-container-bg {
    background-color: #0b42c1;
    -webkit-transition: background-color 1000ms linear;
    -ms-transition: background-color 1000ms linear;
    transition: background-color 1000ms linear;
    padding-bottom: 20px;
    height: 80px
}



@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap");
.navbar-logo{
    text-decoration: none;
    color: white;
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    margin: 20px;
    flex-direction: row;
    display: flex;
}

.nav-item{
    border-top: 4px solid rgba(0, 0, 0, 0);
    padding-top: 25px;
    padding-bottom: 4px; 
    text-decoration: none;
    color: white;
    font-family: "Poppins", sans-serif;
    margin: 20px;
    font-size: 25px;
    border-bottom: 3px solid rgba(0, 0, 0, 0);
    font-weight: 100;
}

.nav-item:hover{
    border-bottom: 3px solid white;
    transition: all 0.2s ease-out;
}

.navlink-container{
    align-items: center;
    max-width: 1200px;
    width: fit-content;
    flex-direction: row;
    display: flex;
    margin-right: 2%;
    margin-left: auto;
}

#checkbox {
    display: none;
    visibility: hidden;
    pointer-events: none;
}


.logoImage {
    position: relative;
    width: 50px;
    border-radius: 100%;
    height: auto;
    margin-top: 15px;
    margin-left: 2%;
}
.logoText {
    position: absolute;
    margin-left: 70px;
}
.dropdown {
    display: none;
}
@media (max-width: 1200px) {
    .hamburger{
        margin-left: 28px;
        position: relative;
        padding: 20px;
        padding-right: 0;
        display: flex;
        align-items:stretch;
        justify-content: right;
        cursor: pointer;
        width: 21px;
        padding-top: 40px;
        pointer-events: all;
    }
    
    .line{
        position: absolute;
        width: 21px;
        height: 2px;
        background-color: white;
        color: white;
        border-radius: 3px;
        transition: transform .25s, opacity .35s;
        
    }
    
    .line--top{
        transform: translateY(-7px);
    }
    .line--bottom{
        transform: translateY(7px);
    }
    
    #checkbox:checked~.line--top{
        transform: rotate(45deg);
    }
    
    #checkbox:checked~.line--middle{
        opacity: 0;
    }
    #checkbox:checked~.line--bottom{
        transform: rotate(-45deg);
    }
    .navbar {
        width: 100%;
        max-width: 100%;
    }
    .nav-item {
        visibility: hidden;
        display: none;
        pointer-events: none;
       
    }
    .logoText {
        font-size: 20px;
        margin-top: 25px;
    }
    .hamburger {
        float: right;
        padding: 0;
        margin: 0;
        margin-top: 15px;
        width: 100%;

    }
    .navbar-container {
        width: 100%;
        max-width: 100%;
        
    }
    .navlink-container {
        width: auto;
        margin-right: 10%;
    }
    .dropdown {
        position: absolute;
        height: 110px;
        width: 90%;
        top: 23px;
        pointer-events: none;
        display: inline;
    }
    .dropdownMenu {
        position: absolute;
        width: 100%;
        left: 0px;
        background-color: #0b42c1;
        z-index: 998;
        border-radius: 15px;
        box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.5);
        opacity: 0;
        top: 40px;
        transform: translateY(-20px);
        transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;
        justify-content: center;
        align-items: center;
        padding-bottom: 5px;
        pointer-events: none;
    }


    #checkbox:checked~.dropdownMenu {
        opacity: 1;
        transform: translateY(0px);
        pointer-events: all;
    }
    .nav-itemDrop{

        text-decoration: none;
        color: white;
        font-family: "Poppins", sans-serif;
        font-size: 25px;
        
        font-weight: 100;
        display: block;
        text-align: center;
        width: fit-content;
        margin-left: 50%;
        transform: translateX(-50%);
    }
    
    .nav-itemDrop:hover{
        border-bottom: 3px solid white;
        transition: all 0.2s ease-out;
    }
  }