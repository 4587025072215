.aboutParent {
    width: 100%;
    background-color: #f4fdff;
    height: fit-content;
    min-height: 600px;
    background-image: linear-gradient(180deg, #f4fdff, #ffffff);
}
.aboutContent {
    width: 38%;
    height: fit-content;
    float: right;
    margin-right: 2%;
}
.teamImageMobile {
    display: none;
    visibility: hidden;
}
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@600&display=swap');
.aboutTitle {
    font-family: 'Titillium Web', sans-serif;
    font-size: 65px;
    text-align: center;
    letter-spacing: 1rem;
    background-image: linear-gradient(90deg, #6795ff,blue);
    color: transparent;
    background-clip: text;
    margin: 0;
    padding-top: 50px;
}
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap");
.aboutText {
    font-family: Poppins, sans-serif;
    font-size: 25px;
    margin-left: 5%;
    margin-right: 5%;
    text-align: center;
    line-height: 2.5em;
}
.aboutImage {
    width: 60%;
    float: left;
    height: fit-content;
    justify-content: center;
    text-align: center;
    margin-top: 50px;
    
}
.teamImage {
    width: 65%;
    justify-content: center;
    text-align: center;
    overflow: hidden;
    border-radius: 5%;
}
@media (max-width: 1000px) {
    .aboutParent {
        display: flex;
        flex-flow: column;
    }
    .aboutTitle {
        font-size: 35px;
        letter-spacing: normal;
        order: 1;
    }
    .aboutImage {
        display: none;
        visibility: none;
    }
    .aboutText {
        font-size: 20px;
    }
    .aboutContent {
        width: 100%;
        height: fit-content;
        float: left;
        order: 3;
    }
    .aboutImageMobile {
        display: flex;
        visibility: visible;
        height: fit-content;
        justify-content: center;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 10px;
        
    }
    .teamImageMobile {
        display: flex;
        visibility: visible;
        width: 90%;
        justify-content: center;
        text-align: center;
        border-radius: 5%;
    }
}