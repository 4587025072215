.landingParent {
    background-image: url(landingPageBg.jpg);
    background-size:cover;
    width: 100%;
    height: 1080px;
    background-repeat: no-repeat;
    padding: 0;
    margin: 0;
    display:flex;
    top: 0px;
}
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap");
.landingContent {
    position: relative;
    top: 100px;
    font-size: 60px;
    margin-right: 40%;
    margin-left: 3%;
    font-family: "Poppins";
    background-image: linear-gradient(90deg, #e7effb, #00bbff);
    color: transparent;
    background-clip: text;
}

.landingTitle {
    -webkit-text-fill-color: transparent; 
    background-image: linear-gradient(90deg, #e7effb, #00bbff);
    background-size: 200% auto;
    -webkit-background-clip: text;
    animation: textclip 4s linear ;
}

@keyframes textclip {
  to {
    background-position: -200% center;
  }
}


.landDonate{
    text-decoration: none;
    width: 300px;
    height: 75px;
    margin-top: 40px;
    background-color: #fdc500;
    border: none;
    transform: translateX(100%);
    border-radius: 15px;
    animation: right-slide 0.7s ease-out 0.1s;
    animation-fill-mode: forwards;
    box-shadow: 0px 0px 40px 0px #fdc500;
    transition: all 0.2s ease-out;
    margin-right: 100px;
    transform: translateX(40%);
}

.landingButton{
    width: 300px;
    height: 75px;
    margin-top: 40px;
    border: none;
    background-color: #fdc500;
    text-decoration: none;
    color: white;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 30px;
    transition: all 0.2s ease-out;
    padding: 15px 10px 15px 10px;
}
.landingButton:hover{
    color: black;
    transition: all 0.2s ease-out;
}
@media (max-width: 1000px) {
    .landDonate {
        margin: 0px;
        padding: 0px;
        justify-content: center;
        align-items: center;
        margin-left: 50%;
        transform: translateX(-50%);
    }
    .landingButton{
        margin: 0px;
        padding: 0px;
    }
    .landingContent {
        margin: 0px;
        padding: 0px;
        font-size: 22px;
        line-height: 3;
        width: 100%;
        overflow-x: hidden;
    }
    .landingTitle {
        text-align: center;
    }
}