@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@600&display=swap');
.partnersContainer {
    width: 100%;
    max-width: 100%;
    height: 500px;
    background-color: #ffffff;
    border: 0;
    padding: 0;
    margin: 0;
    justify-content: center;
    margin-top: 150px;
    margin-bottom: 500px;
}
.partnersTitle {
    width: 100%;
    font-family: 'Titillium Web', sans-serif;
    font-size: 65px;
    text-align: center;

}
.partnerText {
    width: 60%;
    float: left;
    height: 1000px;
}

.partnerImages {
    width: 40%;
    float: right;
    height: 1000px;
}
.logoImages {
    width: 30%;
    margin-right: 15%;
    margin-bottom: 50px;
}
.partnersDescription {
    width: 80%;
    font-family: Poppins, sans-serif;
    font-size: 20px;
    font-weight: 100;
    margin-left: 10%;
    line-height: 3em;
    font-style: italic;
}

@media (max-width: 1000px) {
    .partnersContainer {
        height: fit-content;
        display: flex;
        flex-flow: column;
        margin: 0px;
        padding-top: 50px;
    }
    .partnerText {
        width: 100%;
        height: fit-content;
    }
    .partnersTitle {
        font-size: 35px;
    }
    .partnersDescription {
        font-size: 15px;
        text-align: center;
        width: 100%;
        margin: 0px;
        padding: 0px;
        padding-left: 5px;
        padding-right: 5px;
        margin-bottom: 30px;
        
    }
    .partnerImages {
        height: fit-content;
        width: 95%;
        float: none;
        align-items: center;
        margin-left: 2.5%;
    }
    .logoImages {
        width: 40%;
        margin-right: 2%;
        margin-bottom: 10px;
        margin-left: 5%;
        float: none;
    }
}