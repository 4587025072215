@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@600&display=swap');
.SponsorsParent {
    width: 100%;
    max-width: 100%;
    height: fit-content;
    margin-top: -43.5px;
    background-image: linear-gradient(180deg, #f9c6b4,  #3772ff);
}
.SponsorsTitle {
    width: 100%;
    font-family: 'Titillium Web', sans-serif;
    font-size: 65px;
    text-align: center;
}
.SponsorsDescription {
    width: 80%;
    font-family: Poppins, sans-serif;
    font-size: 20px;
    font-weight: 500;
    margin-left: 10%;
    text-align: center;
    line-height: 3em;
    font-style: italic;
}

.Images {
    width: 100%;
    text-align: center;
    padding: 30px;
}
.logos {
    width: 400px;
    height: auto;
    border-radius: 30px;
    padding: 10px;
    border-style: double;
    border-width: 6px;
    border-color:gold;
    margin-right: 30px;
    margin-bottom: 30px;
}

@media (max-width: 1000px) {
    .SponsorsParent {
        margin-top: -24px;
    }
    .SponsorsTitle {
        font-size: 35px;
    }
    .SponsorsDescription {
        font-size: 15px;
        text-align: center;
        width: 100%;
        margin: 0px;
        padding: 0px;
        padding-left: 5px;
        padding-right: 5px;
        margin-bottom: 30px;
        
    }
    .Images {
        height: fit-content;
        padding: 0;
        
    }
    .logos {
        width: 40%;
        margin-right: 2%;
        margin-bottom: 10px;
        margin-left: 5%;
        float: none;
        
    }
}