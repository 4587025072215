.infinite-scroll {
    overflow: hidden;
    white-space: nowrap;
    background-color: #0710a2; /* Adjust background color */
    padding: 0px 0;
    font-size: 1.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    color: aliceblue;
    margin-top: -5px; /* tiny white gap, idk a better way to get rid of it */
  }
  
  .scroll-content {
    display: inline-flex;
    /*align-items: center;*/
    animation: scroll 60s linear infinite reverse;
    
  }

  .scroll-item {
    margin-left: 100%;
  }
  
  @keyframes scroll {
    from {
      transform: translateX(600%);
    }
    to {
      transform: translateX(-600%);
    }
  }